import React, { useCallback, useRef } from "react";
import { useDropArea } from "react-use";
import dynamic from "next/dynamic";

import { Button, useToasts, Text } from "@puzzle/ui";
import { colors, styled } from "@puzzle/theme";
import { Paperclip } from "@puzzle/icons";

import { AssociatedEntity } from "graphql/types";
import { useAttachmentManager } from "./hooks/useAttachmentManager/useAttachmentManager";
import { SidebarToggle } from "components/common/SidebarToggle";
import { SingleBillFragment } from "components/dashboard/Accounting/Bills/graphql.generated";
import { FullJournalEntryFragment } from "components/dashboard/Accounting/ManualJournals/graphql.generated";
import { SingleLedgerReconciliationFragment } from "components/dashboard/Accounting/Reconciliation/LedgerReconciliation/graphql.generated";
import { isSupportedFileType } from "./hooks/useAttachmentManager/utils";

const DynamicPDFViewer = dynamic(() =>
  import("components/common/PDFViewer").then((mod) => mod.PDFViewer)
);

const UploadContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  pointerEvents: "none",
  gap: "$2h",
});

const AttachmentRoot = styled("div", {
  height: "100%",
  alignItems: "center",
  backgroundColor: "$rhino900",
  position: "relative",
  borderRadius: "$2",
  border: "1px solid transparent",
  transition: "border-color 0.1s ease-in",
  display: "flex",
  justifyContent: "center",
  whiteSpace: "nowrap",
  overflow: "hidden",

  variants: {
    isDropping: {
      true: {
        borderColor: "$purple400",
        [`${UploadContent}`]: {
          [`${Button}`]: {
            pointerEvents: "none",
          },
        },
      },
      false: {
        [`${UploadContent}`]: {
          [`${Button}`]: {
            pointerEvents: "all",
          },
        },
      },
    },
  },
});

const UPLOAD_ICON_SIZE = 21;

type SupportedEntity =
  | SingleBillFragment
  | FullJournalEntryFragment
  | SingleLedgerReconciliationFragment;
type SupportedUploadEntity =
  | AssociatedEntity.Bill
  | AssociatedEntity.ManualJournalEntry
  | AssociatedEntity.LedgerReconciliation;

export const AttachmentSideBar = ({
  entity,
  inMemoryFile,
  setInMemoryFile,
  isEditor,
  toggleSidebar,
  sidebarOpen,
  uploadEntityType,
  text = "Upload file (optional but recommended)",
}: {
  entity?: SupportedEntity;
  uploadEntityType: SupportedUploadEntity;
  inMemoryFile?: File;
  setInMemoryFile: (file?: File) => void;
  sidebarOpen: boolean;
  toggleSidebar?: () => void;
  isEditor: boolean;
  text?: string;
}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const openFilePicker = useCallback(() => hiddenFileInput.current?.click(), []);
  const { toast } = useToasts();

  const { previewUrl, uploadFile, deleteFile, isUploading } = useAttachmentManager({
    entity,
    uploadEntityType,
    isEditor,
  });

  const handleFiles = useCallback(
    (files: File[] | FileList) => {
      if (!isEditor) return;
      const fileArray = Array.isArray(files) ? files : Array.from(files);
      if (fileArray[0] && !isSupportedFileType(fileArray[0])) {
        toast({ status: "warning", message: "Please upload a PDF" });
        return;
      }

      // Entity doesn't exist yet, preview in memory and upload when they post
      if (!entity) {
        setInMemoryFile(fileArray[0]);
        return;
      }

      // Entity already exists, upload the file
      uploadFile(fileArray);
    },
    [entity, uploadFile, setInMemoryFile, isEditor, toast]
  );

  const onDelete = useCallback(() => {
    if (inMemoryFile) {
      setInMemoryFile(undefined);
    } else {
      deleteFile();
    }
  }, [deleteFile, inMemoryFile, setInMemoryFile]);

  const [dropAreaProps, { over }] = useDropArea({
    onFiles: handleFiles,
  });

  const previewFileUrl = inMemoryFile ? URL.createObjectURL(inMemoryFile) : previewUrl;

  return (
    <>
      {previewFileUrl && (
        <DynamicPDFViewer
          url={previewFileUrl}
          onToggle={toggleSidebar}
          onDelete={isEditor ? onDelete : undefined}
        />
      )}
      {!previewFileUrl && (
        <AttachmentRoot {...dropAreaProps} isDropping={over && isEditor}>
          {toggleSidebar && (
            <SidebarToggle
              open={sidebarOpen}
              onClick={() => toggleSidebar()}
              css={{
                position: "absolute",
                top: "$3",
                right: "$3",
              }}
            />
          )}

          <UploadContent>
            <Paperclip size={UPLOAD_ICON_SIZE} color={colors.neutral400} />
            <Text size="headingS" weight="bold" color="neutral300">
              {text}
            </Text>
            <Text color="neutral500" size="bodyS">
              Drag and drop files here, or
            </Text>
            <Button
              variant="secondary"
              size="compact"
              onClick={openFilePicker}
              disabled={!isEditor}
              loading={isUploading}
              css={{ marginTop: "-$1" }}
            >
              Browse my computer
            </Button>
          </UploadContent>
        </AttachmentRoot>
      )}
      <input
        type="file"
        ref={hiddenFileInput}
        accept=".pdf"
        style={{ display: "none" }}
        onChange={(e) => {
          if (!e.target.files) return;
          handleFiles(e.target.files);
        }}
      />
    </>
  );
};

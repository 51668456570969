import React, { ComponentPropsWithRef } from "react";
import * as Stitches from "@stitches/react";
import rgba from "polished/lib/color/rgba";

import { UnstyledButton } from "@puzzle/ui";
import { colors, styled } from "@puzzle/theme";
import { MinimizeRight, MinimizeLeft } from "@puzzle/icons";

const SidebarToggleRoot = styled(UnstyledButton, {
  padding: "$1",
  lineHeight: 0,
  borderRadius: "$ellipse",
  transition: "background 0.15s ease-out",
  color: "$neutral500",

  "&:hover, &:focus": {
    backgroundColor: rgba(colors.neutral50, 0.04),
    color: "$neutral300",
  },
});

export const SidebarToggle = (
  props: ComponentPropsWithRef<"button"> &
    Stitches.VariantProps<typeof SidebarToggleRoot> &
    Stitches.CSS
) => {
  return (
    <SidebarToggleRoot {...props}>
      {props.open ? <MinimizeLeft /> : <MinimizeRight />}
    </SidebarToggleRoot>
  );
};
